<!-- =========================================================================================
    File Name: DashboardEcommerce.vue
    Description: Dashboard - Ecommerce
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <!-- <b-row class="match-height">
              <b-col cols="12">
                <tab-position :tabs="tabs" :name="'Project'" />
              </b-col>
            </b-row> -->
            <!-- <h4 class="header-title">Advanced Data Table</h4> -->
            <p class="text-muted font-13 mb-0"></p>
            <!-- Table -->
            <div>
              <good-table-column-search
                :moduleapi="`${this.baseApi}/project`"
                title="Project"
                :columns="columns"
                routerpath="/master/crm/addprojectmaster"
                :formOptions="formOptions"
                :edit="edit"
                :add="add"
                :canDelete="canDelete"
                viewComponent="ProjectShow"

              >
              
              <template #customField='{props,getGridData}'>
                  <div class="w-full" style="text-align: center" v-if="props.column.field == 'active' && props.row.projectstatus">
                    <div class="d-none">
                      {{  enabledisable[props.row.id] = props.row.active?true:false  }}
                    </div>
                    <b-form-checkbox v-model="enabledisable[props.row.id]" @input="handelClickEnableDisable(props.row.id,getGridData)"
                      class="custom-control-primary" name="check-button" switch>
                    </b-form-checkbox>
                  </div>
                  <span v-else-if="props.column.field === 'projectstatus'">
                    <b-badge :variant="statusVariant(props.row.projectstatus)">
                      {{ props.row.projectstatus }}
                    </b-badge>
                  </span>
              </template>
              
              </good-table-column-search>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from '@/components/axios';
import TabPosition from "../../../../components/TabView.vue";
import GoodTableColumnSearch from "../../../../components/GoodTableColumnSearch.vue";
import moment from "moment";
import { BFormCheckbox,BBadge } from 'bootstrap-vue';
const baseApi = process.env.VUE_APP_APIENDPOINT;
export default {
  components: {
    GoodTableColumnSearch,
    TabPosition,
    BFormCheckbox,
    BBadge
  },
  data() {
    return {
      // tabs: [
      //   { name: 'Lead Source', route: '/master/crm/leadsource', icon: 'CompassIcon' },
      //   { name: 'Lead Stage', route: '/master/crm/leadstage', icon: 'CommandIcon' },
      //   { name: 'Campaign Name', route: '/master/crm/campaign', icon: 'MinimizeIcon' },
      //   { name: 'Reason', route: '/master/crm/reason', icon: 'ApertureIcon' },
      //   { name: 'Channel Partner', route: '/master/crm/channelpartner', icon: 'UsersIcon' },
      //   { name: 'Franchise', route: '/master/crm/franchise', icon: 'FramerIcon' },
      //   { name: 'Project', route: '/master/crm/project', icon: 'PackageIcon' },
      //   { name: 'Plot', route: '/master/crm/plot', icon: 'MapIcon' },
      //   { name: 'Land', route: '/master/crm/land', icon: 'SlackIcon' },
      //   { name: 'Commission', route: '/master/crm/commission', icon: 'GitCommitIcon' },
      //   { name: 'Project Mapping', route: '/master/crm/projectmapping', icon: 'MapPinIcon' }
      // ],
      baseApi: process.env.VUE_APP_APIENDPOINT,
      formOptions: {
        formTitle: `${
          this.$route.params.id ? "Edit Plot Master" : "Add Plot Master"
        }`,
        submitButtonName: "Add Project",
        submitRouterPath: "/master/crm",
        gridForm: true,
        method: "post",
        action: "add",
        url: `${baseApi}/project`,
        inputFields: [
          {
            name: "company",
            value: 1,
            type: "hidden",
          },
          {
            name: "active",
            value: 1,
            type: "hidden",
          },
          {
            label: "Contract Status ",
            name: "contractstatus ",
            value: "",
            type: "dropdown",
            class: "col-md-6",
            dropdownname: "status",
            required: true,
            responseValue: "fullname",
            options: [
              { name: "Active" },
              { name: "InActive" },
              { name: "Hold" },
            ],
          },
          {
            label: "City",
            name: "city",
            value: "",
            type: "text",
            placeholder: "Enter city",
            class: "col-md-6",
            required: true,
          },
          {
            label: "Contact Person",
            name: "contactperson",
            value: "",
            type: "text",
            placeholder: "Enter Contact Person",
            class: "col-md-6",
            required: true,
          },
          {
            label: "Contact No",
            name: "contactno",
            value: "",
            type: "number",
            placeholder: "Enter Contact No",
            class: "col-md-6",
            required: true,
          },
          {
            label: "E-Mail ID",
            name: "email",
            value: "",
            type: "email",
            placeholder: "Enter Mobile",
            class: "col-md-6",
            required: true,
          },
          {
            label: "GST No.",
            name: "gstno",
            value: "",
            type: "text",
            placeholder: "Enter GST No.",
            class: "col-md-6",
            required: true,
          },
          {
            label: "Project Name",
            name: "name",
            value: "",
            type: "text",
            placeholder: "Enter Project Name",
            class: "col-md-6",
            required: true,
          },
          {
            label: "Project Address",
            name: "projectaddress",
            value: "",
            type: "text",
            placeholder: "Enter Project Address",
            class: "col-md-6",
            required: true,
          },
          {
            label: "Area",
            name: "area",
            value: "",
            type: "text",
            placeholder: "Enter Area",
            class: "col-md-6",
            required: true,
          },
          {
            label: "Lat:",
            name: "lat",
            value: "",
            type: "number",
            placeholder: "Enter Lat",
            class: "col-md-6",
            required: true,
          },
          {
            label: "Lng:",
            name: "lng",
            value: "",
            type: "number",
            placeholder: "Enter Lng",
            class: "col-md-6",
            required: true,
          },
          {
            label: "Property Type",
            name: "propertytype",
            value: "",
            type: "text",
            placeholder: "Enter Property Type",
            class: "col-md-6",
            required: true,
          },
          {
            label: "Property",
            name: "property",
            value: "",
            type: "text",
            placeholder: "Enter Property",
            class: "col-md-6",
            required: true,
          },
          {
            label: "BHK",
            name: "bhk",
            value: "",
            type: "text",
            placeholder: "Enter BHK",
            class: "col-md-6",
            required: true,
          },
          {
            label: "Property Size(Sq ft)",
            name: "propertysize",
            value: "",
            type: "number",
            placeholder: "Enter Property Size",
            class: "col-md-6",
            required: true,
          },
          {
            label: "Rate",
            name: "rate",
            value: "",
            type: "number",
            placeholder: "Enter Rate",
            class: "col-md-6",
            required: true,
          },
          {
            label: "MOU Received",
            name: "moureceived",
            value: "",
            type: "dropdown",
            class: "col-md-6",
            dropdownname: "moureceived",
            required: true,
            responseValue: "name",
            options: [{ name: "Yes" }, { name: "Name" }],
          },
          {
            label: "MOU %",
            name: "mouper",
            value: "",
            type: "text",
            placeholder: "Enter Mou %",
            class: "col-md-6",
            required: true,
          },
          {
            label: "Listing By",
            name: "listingby",
            value: "",
            type: "dropdown",
            class: "col-md-6",
            responseValue: "id",
            dropdownname: "listingby",
            url: "getUsers",
            required: true,
            options: [],
          },
          {
            label: "Property For",
            name: "propertyfor",
            value: "",
            type: "dropdown",
            class: "col-md-6",
            dropdownname: "propertyfor",
            required: true,
            responseValue: "name",
            options: [{ name: "Sales" }, { name: "Rent" }],
          },
          {
            label: "Type",
            name: "type",
            value: "",
            type: "dropdown",
            class: "col-md-6",
            dropdownname: "type",
            required: true,
            responseValue: "name",
            options: [
              { name: "Flat" },
              { name: "House" },
              { name: "Plot" },
              { name: "Office" },
              { name: "Shop" },
              { name: "Other Commercial" },
            ],
          },
          {
            label: "Possession Status:",
            name: "possessionstatus",
            value: "",
            type: "dropdown",
            class: "col-md-6",
            dropdownname: "possessionstatus",
            required: true,
            responseValue: "name",
            options: [{ name: "Under Construction" }, { name: "Ready move" }],
          },
          {
            label: "Available From",
            name: "avlfrom",
            value: "",
            type: "text",
            placeholder: "Enter Available From",
            class: "col-md-6",
            required: true,
          },
          {
            label: "Key Availability:",
            name: "keyavailability",
            value: "",
            type: "text",
            placeholder: "Enter Key Availability",
            class: "col-md-6",
            required: true,
          },
          {
            label: "Project Banner",
            name: "projectbanner",
            value: "",
            type: "fileinput",
            placeholder: "select Project Banner",
            class: "col-md-6",
            required: true,
          },
          {
            label: "Project MOU",
            name: "projectmou",
            value: "",
            type: "fileinput",
            placeholder: "select Project MOU",
            class: "col-md-6",
            required: true,
          },
          {
            label: "Logo",
            name: "projectlogo",
            value: "",
            type: "fileinput",
            placeholder: "select Logo",
            class: "col-md-6",
            required: true,
          },
          {
            label: "Project Status",
            name: "postatus",
            value: "",
            type: "dropdown",
            class: "col-md-6",
            dropdownname: "projectstatus",
            required: true,
            responseValue: "name",
            options: [
              { name: "ONGOING" },
              { name: "Complited" },
              { name: "Upcoming" },
            ],
          },
          {
            label: "Project Type",
            name: "projecttype",
            value: "",
            type: "dropdown",
            class: "col-md-6",
            dropdownname: "projecttype",
            required: true,
            responseValue: "name",
            options: [{ name: "Our Project" }, { name: "Associate project" }],
          },
          {
            label: "Company",
            name: "company",
            value: "",
            type: "dropdown",
            class: "col-md-6",
            responseValue: "id",
            dropdownname: "company",
            url: "company",
            required: true,
            options: [],
          },
        ],
      },
      columns: [
        {
          label: "Project Name",
          field: "name",
          filterOptions: {
            enabled: true,
            placeholder: "Search Project Name",
          },
        },
        //
        {
          label: "Status",
          field: "projectstatus",
          filterOptions: {
            enabled: true,
            placeholder: "Search Status",
          },
        },
        {
          label: "Phase Name",
          field: "phasename",
          filterOptions: {
            enabled: true,
            placeholder: "Search Phase Name",
          },
        },
        {
          label: "Project Type",
          field: "projecttype",
          filterOptions: {
            enabled: true,
            placeholder: "Search Project Type",
          },
        },
        // {
        //   label: "Price (per sq. yard)",
        //   field: "pricepersqyd",
        //   filterOptions: {
        //     enabled: true,
        //     placeholder: "Search price",
        //   },
        // },
        {
          label: "District",
          field: "city",
          filterOptions: {
            enabled: true,
            placeholder: "Search District",
          },
        },
        {
          label: "State",
          field: "state",
          filterOptions: {
            enabled: true,
            placeholder: "Search District",
          },
        },
        {
          label: "Company",
          field: "companyname",
          type: "dropdown",
          url: "company",
          responseValue: "name",
          filterOptions: {
            enabled: true,
            placeholder: "Search Company",
          },
        },
        {
          label: "No of Plots",
          field: "totalPlots",
          filterOptions: {
            enabled: true,
            placeholder: "Search No of Plots",
          },
        },
        {
          label: "Vacant Plots",
          field: "Vacant",
          filterOptions: {
            enabled: true,
            placeholder: "Search No of Plots",
          },
        },
        {
          label: "Sold Plots",
          field: "Sold",
          filterOptions: {
            enabled: true,
            placeholder: "Search No of Plots",
          },
        },
        {
          label: "Hold Plots",
          field: "Hold",
          filterOptions: {
            enabled: true,
            placeholder: "Search No of Plots",
          },
        },
        {
          label: "Booked Plots",
          field: "Booked",
          filterOptions: {
            enabled: true,
            placeholder: "Search No of Plots",
          },
        },
        {
          label: "Registered Plots",
          field: "Registered",
          filterOptions: {
            enabled: true,
            placeholder: "Search No of Plots",
          },
        },
        {
          label: "Assign To",
          field: "assigntoRole",
          filterOptions: {
            enabled: true,
            placeholder: "Search No of Plots",
          },
        },

        {
          label: "Enable/Disable",
          field: "active",
          filterOptions: {
            enabled: true,
            placeholder: "Search OP FP No",
          },
        },
        {
          label: "Action",
          field: "action",
        },
      ],
      edit: "",
      add: "",
      canDelete: "",

      enabledisable: {},
      accessToken: localStorage.getItem("accessToken"), // JSON.parse()
    };
  },
  methods:{
    async handelClickEnableDisable(id,getGridData) {
      const data = {
        id: id,
        active: this.enabledisable[id] ? 1 : 0,
      };

      const reportauthority = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/project/change-active`,
        data: JSON.stringify(data),
      };
      await axios(reportauthority)
        .then((response) => {
          this.$swal({
            title: "Submited",
            text: `${this.enabledisable[id]
              ? "Project Enable Successfully"
              : "Project Disable Successfully"
              }`,
            icon: "success",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
          getGridData()
        })
        .catch((error) => console.log(error, "error"));
    },
  },
  mounted() {
    const userData = JSON.parse(localStorage.getItem("UserRights"));
    const role = JSON.parse(localStorage.getItem("userData")).role;
    if (role !== "admin") {
      userData.map((item) => {
        if (item.modulename == "Project") {
          this.edit = item.edit;
          this.add = item.add;
          this.canDelete = item.delete;
          if (
            item.edit !== 1 &&
            item.add !== 1 &&
            item.delete !== 1 &&
            item.view !== 1
          ) {
            this.$router.push("/");
          }
        }
      });
    }
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
